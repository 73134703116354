button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.btnAddUser {
    background: var(--button-tong);
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    border: none;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.btnAddUser:hover {
    background-color: #4895ef;
    color: #fff;
    border: none;
}

.btnAddUser {
    float: right;
}

.tableAdmin{
    background: #fff;
}

.DateBirthdayUser{
    width: 100%;
}

.styleMail .ant-table-cell:nth-child(3), .styleMail .ant-table-cell:nth-child(4)  {
    text-align: left!important;
  }

