

.custom-footer {
  background: black;
}

.custom-footer-login {
  padding-top: 20px !important;
  text-align: center;
  height: 6vh !important;
  background: linear-gradient(to bottom right, #1c92d2 0, #6dcadd) !important;
}

.custom-footer .ant-layout-footer {
  background: none;
}
