@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

* {
  padding: 0;
  margin: 0;
}

:root {
  --background-tong: #001529;
  --button-tong: #f79e02;
}

.logo {
  height: 50px;
  background: url(./containers/RainScales-Logo-Big.png) no-repeat;
  text-align: center;
  background-size: 136px 50px;
  margin: 10px;
  margin-left: 24px;
}

.MenuItem {
  width: 100% !important;
}

.ant-layout-content {
  height: 94%;
}

.ant-layout-header {
  height: 70px !important;
}

.FormLogin {
  /* border: 1px solid black; */
  background: rgba(0, 0, 0, 0.534);
  border-radius: 20px;
  width: 30%;
  text-align: center;
}

.content {
  padding: 80px 0;
}

.logoLogin {
  margin-top: 40px;
}

.login-form-forgot {
  font-weight: 500;
  font-size: 18px;
}

.inputPageLogin {
  /* background: #edf2f7; */
  height: 48px;
  border-radius: 10px;
}

.logo-login h1 {
  color: #333;
  font-size: 75px;
  font-weight: 500;
  font-family: "Lobster", cursive;
}

.logo-login-mobile h1 {
  color: #333;
  font-size: 50px;
  font-weight: 400;
  font-family: "Lobster", cursive;
}

.bn632-hover {
  width: 100%;
  height: 48px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  padding: 0px 30px;
  color: white;
  text-align: center;
  border: none;
  background-size: 300% 100%;

  --moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:hover {
  color: white;
  background: transparent;
}

.bn632-hover:hover {
  background-position: 100% 0;
  --moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.custom-btn {
  display: block;
  position: absolute;
  height: 50;
  border: none;
  background: white;
  font-size: 20px;
  cursor: pointer;
}

.bn632-hover.bn20 {
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  );
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.FooterLogin {
  background: transparent !important;
}

.container.loginFormInput .ant-form-item-control-input {
  width: 70%;
  margin: auto;
}

.layoutUserLogin {
  background-size: cover;
  /* background: linear-gradient(to bottom right,#1c92d2 0,#6dcadd); */
  background-color: black;
}

.SiderLayout {
  background: var(--background-tong);
  flex: 0 0 250px !important;
  width: 220px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--button-tong) !important;
  /* padding-top: 10px;
  padding-bottom: 10px;  */
  border-radius: 10px;
}

/* .SiderLayout
  a
  li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.css-1o6fhzm-MuiButtonBase-root-MuiMenuItem-root {
    background-color: var(--button-tong) !important;
    padding-top: 10px;
    padding-bottom: 10px; 
    border-radius: 10px;
} */

.SiderLayout .ant-layout-sider-trigger {
  width: 250px !important;
}

.ContentUser {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 768px) {
  .FormLogin {
    width: 70% !important;
    margin-bottom: 30%;
  }

  .logo {
    height: 40px;
    background: url(./containers/VBPO_Logo.png) no-repeat;
    text-align: center;
    background-size: 100px 40px;
    margin: 15px;
    margin-left: 10px;
  }
}

@media (max-width: 1600px) {
  .FormLogin {
    width: 70% !important;
    margin-bottom: 30%;
  }

  .logo {
    height: 40px;
    background: url(./containers/VBPO_Logo.png) no-repeat;
    text-align: center;
    background-size: 100px 40px;
    margin-left: 10px;
  }

  .ant-layout-header {
    height: 60px !important;
  }
}

.styleTableLeTet .ant-table-cell:nth-child(2),
.styleTableLeTet .ant-table-cell:nth-child(3),
.styleTableLeTet .ant-table-cell:nth-child(4),
.styleTableLeTet .ant-table-cell:nth-child(6) {
  text-align: left !important;
}

.styleTableLeTet1 .ant-table-cell:nth-child(2),
.styleTableLeTet1 .ant-table-cell:nth-child(3),
.styleTableLeTet1 .ant-table-cell:nth-child(4),
.styleTableLeTet1 .ant-table-cell:nth-child(5) {
  text-align: left !important;
}

.styleTableContract .ant-table-cell:nth-child(2),
.styleTableContract .ant-table-cell:nth-child(3),
.styleTableContract .ant-table-cell:nth-child(4),
.styleTableContract .ant-table-cell:nth-child(5),
.styleTableContract .ant-table-cell:nth-child(6) {
  text-align: left !important;
}
.tableLogHistory .ant-table-cell:nth-child(2),
.tableLogHistory .ant-table-cell:nth-child(4) {
  text-align: left !important;
}

.site-drawer-render-in-current-wrapper {
  /* position: relative; */
  /* height: 200px; */
  /* padding: 48px; */
  overflow: hidden;
  /* text-align: center; */
  /* background: #fafafa; */
  /* border: 1px solid #ebedf0; */
  /* border-radius: 2px; */
}

.ant-tag > a:first-child:last-child {
  /* display: inline-block; */
  margin: 0 -8px;
  padding: 0 8px;
}

@media only screen and (max-width: 1920px) {
 .css-kjirbw-MuiButtonBase-root-MuiButton-root {
    /* display: -webkit-inline-box; */
    display: -webkit-inline-flex;
    /* display: -ms-inline-flexbox; */
    /* display: inline-flex; */
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 14px !important;
    line-height: unset !important;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 14px !important;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #0288d1;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .css-1jxg36x {
    display: -webkit-inline-flex;
    align-items: center !important;
    font-size: 14px !important;
    line-height: unset !important;
    padding: 6px 14px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .css-kjirbw-MuiButtonBase-root-MuiButton-root {
    /* display: -webkit-inline-box; */
    display: -webkit-inline-flex;
    /* display: -ms-inline-flexbox; */
    /* display: inline-flex; */
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 13px !important;
    line-height: unset !important;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 10px !important;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #0288d1;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .css-1jxg36x {
    display: -webkit-inline-flex;
    align-items: center !important;
    font-size: 13px !important;
    line-height: unset !important;
    padding: 6px 10px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .css-kjirbw-MuiButtonBase-root-MuiButton-root {
    /* display: -webkit-inline-box; */
    display: -webkit-inline-flex;
    /* display: -ms-inline-flexbox; */
    /* display: inline-flex; */
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 12px !important;
    line-height: unset !important;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px !important;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #0288d1;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .css-1jxg36x {
    display: -webkit-inline-flex;
    align-items: center !important;
    font-size: 12px !important;
    line-height: unset !important;
    padding: 6px !important;
  }
}
