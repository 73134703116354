.site-card-wrapper .ant-card-head {
  background: #0091ea;
  padding: 0px;
}

.site-card-wrapper .ant-card-head-title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #fff;
}

.site-card-wrapper .ant-card-body {
  font-size: 30px;
  text-align: center;
  font-weight: 700;
}

.chart-size {
  width: 100%;
  height: 100%;
}

.hide {
  display: none;
}

.datePickerChart {
  width: 40%;
  margin-left: 33%;
  margin-top: 5%;
}

.titleChart {
  font-weight: bold;
  text-align: center;
}

.spaceChart {
  margin-right: 0px;
}

@media screen and (max-width: 1600px) {
  .chart-size {
    width: 800px;
  }
  .site-card-wrapper .ant-card-body {
    font-size: 26px;
    text-align: center;
    font-weight: 700;
    padding: 10px;
  }

  .site-card-wrapper .ant-card-head-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #fff;
    padding: -1px 16px 0px 16px;
    /* margin-top: 3%; */
  }
}

@media screen and (max-width: 1366px) {
  .hide :hover {
    display: block;
    color: red;
  }
  .chart-size {
    width: 100%;
    height: 100%;
  }
}

.table-statistic .ant-table-thead .ant-table-cell {
  background-color: #E2E8F0;
}

.ant-card-grid {
  /* width: 33.33%; */
  padding: 16px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}

#tooltip-statistic.ant-tooltip-inner{
  color: black;
  font-size: 12px;
  font-style: italic;
}