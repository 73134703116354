.logOut {
  margin-top: 12px;
}

.menuLayout {
  background: rgba(51, 51, 51, 0.898) !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: rgb(51 51 51 / 0%) !important;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.menuLayout {
  height: 92vh;
}

.avatar2 {
  color: blue;
  margin-right: 50;
  margin-left: 83%;
}

.btnHomePage {
  width: 10.5%;
  border: none;
}

.logoSize {
  width: 100%;
}

@media (max-width: 1920px) {
  .logoSize {
    width: 200%;
  }
}

.ant-list-bordered .ant-list-item {
  padding-right: 0px;
  padding-left: 15px;
  color: darkcyan;
}

.custom-date {
  margin-top: 4%;
  padding-bottom: 0;
  justify-content: center;
  display: flex;
  height: 30px;
  border-radius: 6px;
  background-color: rgb(236, 226, 226);
}

.text-date {
  margin: "auto";
  font-size: 13px;
  margin-top: 4px;
}

.fiber-icon {
  color: rgb(79, 123, 205);
  margin: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(172, 166, 166);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(134, 132, 132);
}

.custom-list-notification {
  background-color: white;
  border-radius: 10px;
  height: 500px;
  overflow: auto;
  width: 400px;
}

.badge:after {
  content: attr(value);
  font-size: 12px;
  color: #fff;
  background: #e07a2b;
  border-radius: 50%;
  padding: 0 5px;
  position: relative;
  left: 17px;
  top: -27px;
  opacity: 1;
}

.ant-layout-header {
  color: rgba(0, 0, 0, 0.85);
  line-height: 0px;
}

.ant-layout {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.custom-row :hover {
  /* background: rgb(80, 165, 171); */
  opacity: 0.75;
}

.custom-menu {
  width: 160px;
  border-radius: 12px;
  /* background-color:rgba(0, 0, 0, 0) */
}
.custom-menu :hover {
  background-color: rgb(243, 237, 237);
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px;
}

.background-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 6px;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 16px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 14px;
  line-height: 1;
  left: 12px;
  z-index: 100;
}

.input-container:focus-within label {
  transform: translate(0, 5px) scale(0.8);
  color: #0a53e4;
}

.input-container .filled {
  transform: translate(0, 5px) scale(0.8);
  
}

#input-pw{
  margin-top: 10px;
}

