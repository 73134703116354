button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.btnAddUser {
  background: var(--button-tong);
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  border: none;
}

.steps-content {
  margin-top: 20px;
}

.RowThuNhan .ant-col-11 .ant-col.ant-form-item-label {
  flex: 0 0 125px;
}

.btnModal .ant-col-16 {
  max-width: 100% !important;
  text-align: center;
}

.detail-contract :hover {
  background-color: #fdffbd;
  border: 1px solid #fdffbd;
}

.btnModal .ant-col-offset-8 {
  margin-left: 0 !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  background: rgb(214, 214, 214);
  color: black;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background: #ffffff;
}
.ant-picker.ant-picker-disabled {
  background: #ffffff;
  cursor: not-allowed;
  color: black;
}
.ant-picker-input > input[disabled] {
  color: unset;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: unset;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
  width: -webkit-fill-available;
}

.search {
  transition: 2s;
}

@media screen and (max-width: 768px) {
  .ant-col-8 {
  }
}

p {
  margin-bottom: 0;
}

.btnAddNewCustomer {
  height: 32px;
  margin-left: 5px;
  width: 35px;
  /* border: 0.1px solid #1976d2; */
  border: 1px solid #95999c;
  cursor: pointer;
  background: none;
  border-radius: 50px;
}

.ant-table-thead > tr > th {
  position: relative;
  color: rgb(0, 0, 0);
  font-weight: 500;
  text-align: left;
  background: #ffffff;
  /* background: #d9d9d9; */
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.customIconDrawer {
  color: yellowgreen;
  font-size: 25px;
  margin-top: 102%;
  margin-left: 20%;
}

.custom-notification {
  overflow: scroll;
  height: 400px;
  border-radius: 10px;
}

.type-document .ant-select-arrow {
  display: inline-block;
  color: inherit;
  /* font-style: normal; */
  line-height: 0;
  /* text-transform: none; */
  vertical-align: -webkit-baseline-middle;
  /* text-rendering: optimizelegibility; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 30%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.type-document .ant-select-clear {
  position: absolute;
  top: 30%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}

.custom-table.ant-table-wrapper .ant-table-thead > tr > th,
.custom-table.ant-table-wrapper .ant-table-tbody > tr > th,
.custom-table.ant-table-wrapper .ant-table-tbody > tr > td,
.custom-table.ant-table-wrapper tfoot > tr > th,
.custom-table.ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 6px 8px;
  overflow-wrap: break-word;
}
